import moment from 'moment'
import { dotSeparatorCurrencies } from './constants'
import { inTimezone } from './format'

let useDotSeparator: boolean = false

export const updateStatsSeparator = (currency: string): void => {
  useDotSeparator = dotSeparatorCurrencies.includes(currency)
}

export const formatStatIntValue = (value: number): string =>
  useDotSeparator
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

export const formatStatFloatValue = (value: number, precision = 2): string =>
  useDotSeparator
    ? (value || 0)
        .toFixed(precision)
        .replace(/\B(?=(?=\d*\.)(\d{3})+(?!\d))/g, ',')
    : (value || 0)
        .toFixed(precision)
        .replace('.', ',')
        .replace(/\B(?=(?=\d*,)(\d{3})+(?!\d))/g, '.')

// export const formatStatIntValueEN = (value: number): string =>
//   value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
//
// export const formatStatFloatValueEN = (value: number, precision = 2): string =>
//   value.toFixed(precision).replace(/\B(?=(?=\d*\.)(\d{3})+(?!\d))/g, ',')
//
// export const formatStatIntValueEU = (value: number): string =>
//   value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
//
// export const formatStatFloatValueEU = (value: number, precision = 2): string =>
//   value
//     .toFixed(precision)
//     .replace('.', ',')
//     .replace(/\B(?=(?=\d*,)(\d{3})+(?!\d))/g, '.')
//
// export const swapFormats = (value: string): string => {
//   value.replace(',', '_').replace(/\./g, ',').replace('_', '.')
// }

export const formatStat = (
  value: number,
  valueType = '',
  reversed = false,
  hideType = false
): string => {
  if (valueType === 'XXX') valueType = 'DKK'

  switch (valueType) {
    case 'integer':
      return formatStatIntValue(value)

    case 'float':
      return formatStatFloatValue(value)
    case 'float0':
      return formatStatFloatValue(value, 0)
    case 'float1':
      return formatStatFloatValue(value, 1)
    case 'float2':
      return formatStatFloatValue(value, 2)
    case 'float3':
      return formatStatFloatValue(value, 3)

    case 'trend':
      return formatTrend(value)
    case 'trend0':
      return formatTrend(value, 0)
    case 'trend1':
      return formatTrend(value, 1)
    case 'trend2':
      return formatTrend(value, 2)
    case 'trend3':
      return formatTrend(value, 3)

    case '~trend':
      return formatTrend(value).replace(/,0+%/, '%')
    case '~trend0':
      return formatTrend(value, 0).replace(/,0+%/, '%')
    case '~trend1':
      return formatTrend(value, 1).replace(/,0+%/, '%')
    case '~trend2':
      return formatTrend(value, 2).replace(/,0+%/, '%')
    case '~trend3':
      return formatTrend(value, 3).replace(/,0+%/, '%')

    case 'percent':
      return `${formatStatFloatValue(value)}%`
    case 'percent0':
      return `${formatStatFloatValue(value, 0)}%`
    case 'percent1':
      return `${formatStatFloatValue(value, 1)}%`
    case 'percent2':
      return `${formatStatFloatValue(value, 2)}%`
    case 'percent3':
      return `${formatStatFloatValue(value, 3)}%`

    default:
      if (Number.isInteger(value)) {
        if (hideType) {
          return formatStatIntValue(value)
        } else if (reversed) {
          return formatStatIntValue(value) + ' ' + (valueType || '')
        } else {
          return (valueType || '') + ' ' + formatStatIntValue(value)
        }
      } else {
        if (hideType) {
          return formatStatFloatValue(value)
        } else if (reversed) {
          return formatStatFloatValue(value) + ' ' + (valueType || '')
        } else {
          return (valueType || '') + ' ' + formatStatFloatValue(value)
        }
      }
  }
}

export const formatStatReversed = (value: number, valueType: string) =>
  formatStat(value, valueType, true)

export const formatCurrency = (value: number, valueType: string) => {
  if (valueType === 'XXX') valueType = 'DKK'
  return (valueType || '') + ' ' + formatStatFloatValue(value)
}

export const formatCurrencyReversed = (value: number, valueType: string) => {
  if (valueType === 'XXX') valueType = 'DKK'
  return formatStatFloatValue(value) + ' ' + (valueType || '')
}

const parseForDiff = (value: string | number): number => {
  if (typeof value === 'number') {
    return value
  } else {
    return parseFloat(
      value
        .replace(/[^\d,.]/g, '')
        .replace(/,/g, '.')
        .replace(/\.+$/, '')
        .replace(/\.(?=.*\.)/g, '')
        .replace(/\.(?=\d{3,4}$)/g, '')
    )
  }
}
export const formatDiff = (current: number, previous: number) => {
  const a = parseForDiff(current)
  const b = parseForDiff(previous)
  if (!a && !b) return ''
  if (!a) return '-100%'
  if (!b) return '+100%'
  const percent = (a / b) * 100 - 100
  if (percent > 0) {
    return `+${Math.round(percent)}%`
  } else if (percent < 0) {
    return `${Math.round(percent)}%`
  } else {
    return '0%'
  }
}

export const formatStatNumberByCurrency = (
  value: number,
  currency: string,
  unit: string = ''
) => {
  let calculatedValue = ''
  if (!value) {
    calculatedValue = formatStatIntValue(0)
  } else if (Number.isInteger(value)) {
    calculatedValue = formatStatIntValue(value)
  } else {
    calculatedValue = formatStatFloatValue(value)
  }
  return calculatedValue + unit
}

export const formatTrend = (value: number, precision = 2): string => {
  return `${formatStatFloatValue(value, precision)}%`
}

export const formatCommission = (
  commissionType: string,
  valueFlat: number,
  valuePercentage: number,
  valueCurrency: string,
  reversed = false
) => {
  let value = 0
  let valueType = valueCurrency || 'percent2'

  if (valueFlat || commissionType === 'CPC') {
    value = valueFlat
    valueType = valueCurrency || ''
  } else if (valuePercentage) {
    value = valuePercentage
    valueType = 'percent2'
  }

  return formatStat(value, valueType, reversed)
}

export const formatShortStat = (value: number, precision = 2): string => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((item) => value >= item.value)
  return item
    ? (value / item.value).toFixed(precision).replace(rx, '$1') + item.symbol
    : '0'
}

interface StatisticsRow {
  conversionCount: number
  rejectedConversionCount: number
  conversionCommission: number
  rejectedConversionCommission: number
  cpcCommission: number
  rejectedCpcCommission: number
  conversionTotalCommission: number
  conversionPendingCommission: number
  conversionRejectedCommission: number
  conversionSale: number
  conversionRejectedSale: number
  clickCount: number
  clicksCount?: number
  pageviewCount: number
  clickNetworkUuids?: string
  conversionNetworkUuids?: string
  clickTrackerUuids?: string
  conversionTrackerUuids?: string
  currencyCode?: string
  activeSinceDatetime?: string
  prev?: Omit<StatisticsRow, 'prev'>
}
interface StatisticsResponse {
  pageviewCount: string
  clickCount: string
  ctr: string
  conversionCount: string
  cr: string
  rejectedCount: string
  rr: string
  conversionSale: string
  conversionCommission: string
  conversionClickCommission: string
  conversionPendingCommission: string
  conversionRejectedCommission: string
  conversionTotalCommission: string
  grossSale: string
  grossConversionCount: string
  epc: string
  epm: string
  ecr: string
  networkUuids: string[]
  trackerUuids: string[]
  activeSinceDatetime: string
  prev?: Omit<StatisticsResponse, 'prev'>
}
export const calculateValues = (
  row: StatisticsRow,
  currencyCode?: string
): StatisticsResponse => {
  const {
    conversionCount,
    rejectedConversionCount,
    conversionCommission,
    rejectedConversionCommission,
    conversionTotalCommission,
    conversionPendingCommission,
    conversionRejectedCommission,
    cpcCommission,
    conversionSale,
    conversionRejectedSale,
    pageviewCount,
    clickNetworkUuids,
    conversionNetworkUuids,
    clickTrackerUuids,
    conversionTrackerUuids,
    activeSinceDatetime,
  } = row

  const clickCount = row.clickCount || row.clicksCount || 0
  const currency = row.currencyCode || currencyCode || 'DKK'

  const conversionTotalCount = conversionCount + rejectedConversionCount

  const ctr = (clickCount / (pageviewCount || 1)) * 100
  const cr = (conversionCount / (clickCount || 1)) * 100
  const epc = conversionTotalCommission / (clickCount || 1)
  const epm = conversionTotalCommission / ((pageviewCount || 1) / 1000)
  const ecr = (conversionTotalCommission / conversionSale || 1) * 100
  const rr = (rejectedConversionCount / (conversionTotalCount || 1)) * 100

  const grossSale = conversionSale + conversionRejectedSale
  const grossConversionCount = conversionCount + rejectedConversionCount

  const networkUuids = [
    ...(clickNetworkUuids || '').split(','),
    ...(conversionNetworkUuids || '').split(','),
  ]
    .filter((v) => v)
    .filter((v, i, a) => a.indexOf(v) === i)
  const trackerUuids = [
    ...(clickTrackerUuids || '').split(','),
    ...(conversionTrackerUuids || '').split(','),
  ]
    .filter((v) => v)
    .filter((v, i, a) => a.indexOf(v) === i)

  return {
    pageviewCount: formatStatNumberByCurrency(pageviewCount, currency),
    clickCount: formatStatNumberByCurrency(clickCount, currency),
    ctr:
      pageviewCount > 0 ? formatStatNumberByCurrency(ctr, currency, '%') : '-',
    conversionCount: formatStatNumberByCurrency(conversionCount, currency),
    cr: clickCount > 0 ? formatStatNumberByCurrency(cr, currency, '%') : '0%',
    rejectedCount: formatStatNumberByCurrency(
      rejectedConversionCount,
      currency
    ),
    rr: conversionTotalCount > 0 ? formatStat(rr, 'percent2') : '-',
    conversionSale: formatCurrencyReversed(conversionSale, currency),
    conversionCommission: formatCurrencyReversed(
      conversionCommission,
      currency
    ),
    conversionClickCommission: formatCurrencyReversed(
      cpcCommission || conversionTotalCommission - conversionCommission,
      currency
    ),
    conversionPendingCommission: formatCurrencyReversed(
      conversionPendingCommission,
      currency
    ),
    conversionRejectedCommission: formatCurrencyReversed(
      conversionRejectedCommission,
      currency
    ),
    conversionTotalCommission: formatCurrencyReversed(
      conversionTotalCommission,
      currency
    ),
    grossSale: formatCurrencyReversed(grossSale, currency),
    grossConversionCount: formatStatNumberByCurrency(
      grossConversionCount,
      currency
    ),
    epc: clickCount > 0 ? `${formatCurrencyReversed(epc, currency)}` : '-',
    epm: pageviewCount > 0 ? `${formatCurrencyReversed(epm, currency)}` : '-',
    ecr:
      conversionSale > 0
        ? formatStatNumberByCurrency(ecr, currency, '%')
        : '0%',
    activeSinceDatetime: activeSinceDatetime
      ? moment(activeSinceDatetime).format('YYYY-MM-DD')
      : '',
    networkUuids,
    trackerUuids,
    prev: row.prev ? calculateValues(row.prev, currencyCode) : undefined,
  }
}

export const calculateConversionValues = (
  conversion
): {
  advertiserDomain: string
  conversionDatetime: string
  channelDomain: string
  conversionSale: string
  conversionCommission: string
  conversionUuid: string
  conversionSaleOrderId: string
  conversionStatusKey: string
  conversionNetworkTransactionId: string
  conversionSubId: string
  conversionSubId1: string
  conversionSubId2: string
  conversionSubId3: string
  conversionSubId4: string
  conversionSubId5: string
} => {
  const { advertiser, channel } = conversion

  return {
    'advertiserDomain':
      conversion.conversionName || advertiser.advertiserDomain || 'Unknown',
    'conversionDatetime': inTimezone(conversion.conversionDatetime),
    'channelDomain': channel.channelDomain || 'Unknown',
    'conversionSale': formatCurrency(
      conversion.conversionSale,
      conversion.conversionCurrencyCode || 'DKK'
    ),
    'conversionCommission': formatCurrency(
      conversion.conversionCommission,
      conversion.conversionCurrencyCode || 'DKK'
    ),
    'conversionUuid': conversion.conversionUuid,
    'conversionSaleOrderId': conversion.conversionSaleOrderId,
    'conversionStatusKey': conversion.conversionStatusKey,
    'conversionNetworkTransactionId': conversion.conversionNetworkTransactionId,
    'conversionSubId': conversion.conversionSubId,
    'conversionSubId1': conversion.conversionSubId1,
    'conversionSubId2': conversion.conversionSubId2,
    'conversionSubId3': conversion.conversionSubId3,
    'conversionSubId4': conversion.conversionSubId4,
    'conversionSubId5': conversion.conversionSubId5,
  }
}

export const sumTrackerRows = (rowSet: any) => {
  const sum = (key) => (result, row) => result + (row[key] || 0)

  const activeSinceDatetime =
    rowSet
      .map((row: StatisticsRow) => row.activeSinceDatetime)
      .filter(Boolean)
      .sort((a: string, b: string) => a.localeCompare(b))[0] || ''

  return {
    grp: rowSet.map((row) => row.grp).join(','),
    name: rowSet[0].name,
    pageviewCount: rowSet.reduce(sum('pageviewCount'), 0),
    clickCount: rowSet.reduce(sum('clickCount'), 0),
    conversionCommission: rowSet.reduce(sum('conversionCommission'), 0),
    rejectedConversionCommission: rowSet.reduce(
      sum('rejectedConversionCommission'),
      0
    ),
    conversionCount: rowSet.reduce(sum('conversionCount'), 0),
    rejectedConversionCount: rowSet.reduce(sum('rejectedConversionCount'), 0),
    conversionDatetime: rowSet[0].conversionDatetime,
    conversionSale: rowSet.reduce(sum('conversionSale'), 0),
    conversionRejectedSale: rowSet.reduce(sum('conversionRejectedSale'), 0),
    conversionTotalCommission: rowSet.reduce(
      sum('conversionTotalCommission'),
      0
    ),
    conversionPendingCommission: rowSet.reduce(
      sum('conversionPendingCommission'),
      0
    ),
    conversionRejectedCommission: rowSet.reduce(
      sum('conversionRejectedCommission'),
      0
    ),
    cpcCommission: rowSet.reduce(sum('cpcCommission'), 0),
    cpcCount: rowSet.reduce(sum('cpcCount'), 0),
    activeSinceDatetime,
  }
}
